/*
 * @Author: chenyunye C18025010782@163.com
 * @Date: 2023-02-28 17:24:19
 * @LastEditors: chenyunye C18025010782@163.com
 * @LastEditTime: 2023-03-01 19:44:42
 * @FilePath: \demo\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    name: "layout",
    path: "/",
    component: () => import("@/layout"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home"),
        meta: {
          title: "公司信息",
        },
      },
      {
        path: "/product",
        name: "product",
        component: () => import("@/views/product"),
        meta: {
          title: "产品中心",
        },
      },
      {
        path: "/cooperate",
        name: "cooperate",
        component: () => import("@/views/cooperate"),
        meta: {
          title: "合作客户",
        },
      },
      {
        path: "/case",
        name: "case",
        component: () => import("@/views/case"),
        meta: {
          title: "精选案例",
        },
      },
      {
        path: "/trends",
        name: "trends",
        component: () => import("@/views/trends"),
        meta: {
          title: "最新动态",
        },
      },
      {
        path: "/civilization",
        name: "civilization",
        component: () => import("@/views/civilization"),
        meta: {
          title: "八灵文化",
        },
      },
      {
        path: "/recruitment",
        name: "recruitment",
        component: () => import("@/views/recruitment"),
        meta: {
          title: "人才招聘",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
