/*
 * @Author: chenyunye C18025010782@163.com
 * @Date: 2023-02-28 15:54:09
 * @LastEditors: chenyunye C18025010782@163.com
 * @LastEditTime: 2023-03-03 16:58:57
 * @FilePath: \demo\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import "@/assets/icons/iconfont.css";
// import "./rem.js";
import "swiper/swiper.min.css";
import "animate.css";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

import custom from "./custom";
import "./style/index.scss";
createApp(App)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(store)
  .use(router)
  .use(custom)
  .mount("#app");
