<!--
 * @Author: chenyunye C18025010782@163.com
 * @Date: 2023-02-28 15:54:09
 * @LastEditors: chenyunye C18025010782@163.com
 * @LastEditTime: 2023-02-28 17:30:10
 * @FilePath: \demo\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <router-view />
</template>

<style>
body {
  padding: 0;
  margin: 0;
}
/*全局滚动条样式*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  /* //滑块部分 */
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-track {
  /* //轨道部分 */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 5px;
}
/* // chrome 和 safari */

/* // ie10+ */
.element {
  -ms-overflow-style: none;
}
/* // firefox */
element {
  overflow: -moz-scrollbars-none;
}

/* 首页版心宽度 */
.Typography {
  width: 1400px;
  margin: 0 auto;
}
/* 其他页面版心 */
.OtherTypography {
  width: 1400px;
  margin: 0 auto;
}
</style>
