import addclass from "./directives/class";
import config from "./config/index";
export default {
  install(app) {
    //挂载全局对象
    app.config.globalProperties.$CONFIG = config;
    //注册全局指令
    app.directive("addclass", addclass);
  },
};
